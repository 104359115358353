<script setup lang="ts">
import { joinURL } from 'ufo';
import type { PropType } from 'vue';

import type { AuctionAssetBusEntity } from '~/apiClient';

// CONSTANTS
const documents = ['registrationDocument', 'cocDocument', 'maintenanceCard'] as const;

// PROPS
const props = defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const baseURL = useRuntimeConfig().public.mediaBaseUrl;

// DATA
const hasDocuments = computed<boolean>(() => !!props.asset.registrationDocument || !!props.asset.cocDocument || !!props.asset.maintenanceCard);
</script>

<template>
    <div v-if="hasDocuments" class="flex flex-col gap-6">
        <h3 class="text-2xl font-semibold">{{ tt('adp.papers') }}</h3>
        <div class="flex flex-wrap gap-3">
            <template v-for="doc in documents" :key="doc">
                <UButton
                    v-if="asset[doc]"
                    variant="soft"
                    size="lg"
                    :label="tt(`adp.${doc}`)"
                    leading-icon="i-mdi-tray-download"
                    :to="joinURL(baseURL, asset[doc]!)"
                    target="_blank"
                />
            </template>
        </div>
    </div>
</template>
