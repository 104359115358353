import lodashToString from 'lodash-es/toString';
import type { ComputedRef } from 'vue';

export interface UseAuctionDetailParamsReturnType {
    id: ComputedRef<string | null>;
    slug: ComputedRef<string>;
}

export const useAuctionDetailParams = (): UseAuctionDetailParamsReturnType => {
    // COMPOSABLE
    const route = useRoute();
    const getRouteBaseName = useRouteBaseName();

    // DATA
    const slug = computed<string>(() => lodashToString(route.params.slug));

    const id = computed<string | null>(() => {
        if (getRouteBaseName(route) !== 'auction-slug') return null;

        // UUID 4 regexp https://stackoverflow.com/a/38191104
        const id = /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.exec(slug.value);

        return id && id.length > 0 ? id[0] : null;
    });

    return {
        id,
        slug,
    };
};
