<script setup lang="ts">
import lodashSortBy from 'lodash-es/sortBy';
import lodashToNumber from 'lodash-es/toNumber';
import type { PropType } from 'vue';

import type { AuctionAssetBusEntity } from '~/apiClient';
import type { AssetSpecItem, AssetSpecsSection } from '~/types/assetSpecs.type';

// PROPS
const props = defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity>, required: true },
});

// COMPOSABLES
const { tt, formatUnits, td } = useTypedI18n();
const { locale } = useI18n();

// COMPUTED
const descriptionSpecs = computed<AssetSpecsSection>(() => [
    [
        ['asset.firstRegistration', td(props.asset.firstRegistration, 'mmyyyy')],
        ['asset.kilometers', formatUnits.km(props.asset.km)],
        ['asset.antiPollution', tt(`asset.euroNorm.${props.asset.euroNorm}`)],
        ['asset.class.base', props.asset.class.map(busClass => tt(`asset.class.${busClass}`)).join(', ')],
        ['asset.version', props.asset.version.versionValue],
        ['asset.geographicalLocation', props.asset.geographicalLocation],
        [
            'asset.visitDate.base',
            props.asset.visitDateFrom && props.asset.visitDateTo
                ? tt('asset.visitDate.fromTo', {
                      date: td(props.asset.visitDateFrom, 'ddmmyyyy'),
                      fromTime: td(removeTimezone(props.asset.visitDateFrom), 'hh'),
                      toTime: td(removeTimezone(props.asset.visitDateTo), 'hh'),
                  })
                : null,
        ],
    ],
    [
        ['asset.vehicleCondition.base', tt(`asset.vehicleCondition.${props.asset.vehicleCondition}`)],
        ['asset.vin', props.asset.vin],
        ['asset.converter', props.asset.bodyBuilder],
        ['asset.deckType.base', tt(`asset.deckType.${props.asset.deckType}`)],
        ['asset.length', formatUnits.meters(props.asset.length)],
        ['asset.width', formatUnits.meters(props.asset.width)],
        ['asset.height', formatUnits.meters(props.asset.height)],
        ['asset.inspectionExpiryDate', td(props.asset.inspectionExpiryDate, 'mmyyyy')],
    ],
    [
        ['asset.doors.base', tt(`asset.doors.${props.asset.doors}`)],
        ['asset.totalCapacity', props.asset.totalCapacity],
        ['asset.seats', props.asset.seats],
        ['asset.seatsFromS1', props.asset.seatsFromS1],
        ['asset.standingPlaces', props.asset.standingPlaces],
        ['asset.standingPlacesFromS2', props.asset.standingPlacesFromS2],
        ['asset.companionSeats', props.asset.companionSeats],
        ['asset.wheelchairPlaces', props.asset.wheelchairPlaces],
        ['asset.wheelchairRamp.base', tt(`asset.wheelchairRamp.${props.asset.wheelchairRamp}`)],
    ],
    sortFalseLast([
        ['asset.luggageCapacity', formatUnits.cubicMeters(lodashToNumber(props.asset.luggageCapacity))],
        ['asset.passThroughLuggageCompartment', props.asset.passThroughLuggageCompartment],
    ]),
]);

const transmissionSpecs = computed<AssetSpecsSection>(() => [
    [
        ['asset.gearboxType.base', tt(`asset.gearboxType.${props.asset.gearboxType}`)],
        ['asset.gearboxBrand', props.asset.gearboxBrand],
        ['asset.gearboxModel', props.asset.gearboxModel],
    ],
]);

const engineSpecs = computed<AssetSpecsSection>(() => [
    [
        ['asset.fuel.base', tt(`asset.fuel.${props.asset.fuel}`)],
        ['asset.engineBrand', props.asset.engineBrand],
        ['asset.engineModel', props.asset.engineModel],
    ],
    [
        ['asset.displacement', formatUnits.cc(props.asset.displacement)],
        ['asset.hp', props.asset.hp],
        ['asset.kw', formatUnits.kw(props.asset.kw)],
        ['asset.cylinders', props.asset.cylinders],
    ],
    [
        ['asset.fuelTankCapacity', formatUnits.liters(props.asset.fuelTankCapacity)],
        ['asset.gasTanksCapacity', formatUnits.liters(props.asset.gasTanks)],
        ...insertIf<AssetSpecItem>(!!props.asset.gasTanksMaterial, () => [
            'asset.gasTanksMaterial.base',
            tt(`asset.gasTanksMaterial.${props.asset.gasTanksMaterial!}`),
        ]),
        ['asset.batteryPower', formatUnits.kwh(props.asset.batteryPower)],
        ['asset.chargingType', props.asset.chargingType],
    ],
]);

const wheelsSpecs = computed<AssetSpecsSection>(() => [
    sortFalseLast([
        ['asset.axles', props.asset.axles],
        ['asset.wheels', props.asset.wheels],
        ['asset.wheelsSizeAxle1', props.asset.wheelsSizeAxle1],
        ['asset.wheelsSizeAxle2', props.asset.wheelsSizeAxle2],
        ['asset.wheelsSizeAxle3', props.asset.wheelsSizeAxle3],
        ['asset.spareWheel', props.asset.spareWheel],
        ['asset.alloyWheels', props.asset.alloyWheels],
    ]),
]);

const accessoriesSpecs = computed<AssetSpecsSection>(() => [
    [
        ['asset.acDriver', props.asset.acDriver],
        ['asset.acPassengers', props.asset.acPassengers],
        ['asset.heater', props.asset.heater],
        ['asset.preHeater', props.asset.preHeater],
    ],
    sortFalseLast([
        ['asset.cruiseControl.base', tt(`asset.cruiseControl.${props.asset.cruiseControl}`)],
        ['asset.abs', props.asset.abs],
        ['asset.asr', props.asset.asr],
        ['asset.retarder', props.asset.retarder],
        ['asset.breathalyzer', props.asset.breathalyzer],
        ['asset.tachograph', props.asset.tachograph],
        ['asset.cctv', props.asset.cctv],
        ['asset.internalCameras', props.asset.internalCameras],
        ['asset.externalCameras', props.asset.externalCameras],
        ['asset.retrocamera', props.asset.retrocamera],
    ]),
    [
        ['asset.firePreventionSystem', props.asset.firePreventionSystem],
        ['asset.firePreventionSystemBrand', props.asset.firePreventionSystemBrand],
    ],
    [
        ['asset.indicatorSigns', props.asset.indicatorSigns],
        ['asset.indicatorSignsBrand', props.asset.indicatorSignsBrand],
    ],
    sortFalseLast([
        ['asset.driverBerth', props.asset.driverBerth],
        ['asset.socket220', props.asset.socket220],
    ]),
    sortFalseLast([
        ['asset.wifi', props.asset.wifi],
        ['asset.microphone', props.asset.microphone],
        ['asset.cdPlayer', props.asset.cdPlayer],
        ['asset.dvdPlayer', props.asset.dvdPlayer],
        ['asset.usbPlayer', props.asset.usbPlayer],
    ]),
    sortFalseLast([
        ['asset.kitchen', props.asset.kitchen],
        ['asset.coffeeMachine', props.asset.coffeeMachine],
        ['asset.refrigerator', props.asset.refrigerator],
        ['asset.toilet', props.asset.toilet],
    ]),
    sortFalseLast([
        ...insertIf<AssetSpecItem>(!!props.asset.glasses, () => ['asset.glasses.base', tt(`asset.glasses.${props.asset.glasses!}`)]),
        ['asset.openableWindows', props.asset.openableWindows],
        ['asset.seatUpholstery.base', tt(`asset.seatUpholstery.${props.asset.seatUpholstery}`)],
        ['asset.recliningSeats', props.asset.recliningSeats],
        ['asset.extendableSeats', props.asset.extendableSeats],
        ['asset.safetyBelts', props.asset.safetyBelts],
        ['asset.coffeeTables', props.asset.coffeeTables],
        ['asset.seatPocket', props.asset.seatPocket],
        ['asset.handRest', props.asset.handRest],
        ['asset.footRest', props.asset.footRest],
        ['asset.seatsMonitor', props.asset.seatsMonitor],
        ['asset.seatsRadio', props.asset.seatsRadio],
        ['asset.seatsUsbPorts', props.asset.seatsUsbPorts],
        ['asset.seatsSocket220', props.asset.seatsSocket220],
        ['asset.hatBox', props.asset.hatBox],
        ['asset.curtains', props.asset.curtains],
    ]),
]);

// METHODS
const removeTimezone = (date: string): string => date.replace(/Z$/, '');

const sortFalseLast = (items: AssetSpecItem[]): AssetSpecItem[] =>
    lodashSortBy(items, [(a): boolean | number => (typeof a[1] === 'boolean' ? !a[1] : 0)]);
</script>

<template>
    <section class="flex flex-col gap-6">
        <div v-if="!!asset.additionalNotes[locale]" class="flex flex-col gap-6">
            <h3 class="text-2xl font-semibold">{{ tt('asset.additionalNotes') }}</h3>
            <p>{{ asset.additionalNotes[locale] }}</p>
        </div>

        <AdpSpecsSection :title="tt('asset.description')" :specs="descriptionSpecs" default-open />

        <AdpSpecsSection :title="tt('asset.engine')" :specs="engineSpecs" />

        <AdpSpecsSection :title="tt('asset.transmission')" :specs="transmissionSpecs" />

        <AdpSpecsSection :title="tt('asset.axlesTires')" :specs="wheelsSpecs" />

        <AdpSpecsSection :title="tt('asset.accessories')" :specs="accessoriesSpecs" />

        <AdpDocuments :asset="asset" />
    </section>
</template>
