<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';
import { invoke } from '@vueuse/core';

import { type AuctionItemEntity, BackendErrorCode, type UserMeta } from '~/apiClient';
import { AUCTION_DETAIL_QUERY_KEY_PREFIX } from '~/constants/queryKeyPrefix';
import { NotificationTimeout } from '~/types/notifications.type';

type BidOption = {
    value: number;
    label: string;
};

// PROPS
const props = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { tt, tn } = useTypedI18n();
const { isLoggedIn } = useAuthUtils();
const { openLoginModal } = useModals();
const { notifyError } = useNotification();
const queryClient = useQueryClient();
const { isLgOrGreater } = useResponsive();

// DATA
const userMeta = computed<UserMeta | undefined>(() => props.auction.userMeta);
const isBailModalOpen = ref<boolean>(false);
const bailSuccess = ref<boolean>(false);
const isBidModalOpen = ref<boolean>(false);
const bidAmountToConfirm = ref<number>(0);
const refetchAuctionDetailInProgress = ref<boolean>(false);
const bidOptions = computed<BidOption[]>(() =>
    props.auction.placeableBids.map(b => ({
        value: b,
        label: `+ ${tn(b - props.auction.currentPrice, 'currency')} (${tt('auction.bid.total')} ${tn(b, 'currency')})`,
    }))
);

const bidAmount = ref<number>(props.auction.placeableBids[0]);

const bailNeeded = computed(() => !userMeta.value?.canBid && userMeta.value?.cantBidReason === BackendErrorCode.CantBidUserNoBail);

// HANDLERS
const refetchAuctionDetail = async (): Promise<void> => {
    refetchAuctionDetailInProgress.value = true;
    await queryClient.refetchQueries({ queryKey: [AUCTION_DETAIL_QUERY_KEY_PREFIX], type: 'active' });
    refetchAuctionDetailInProgress.value = false;
};

const login = (): void =>
    openLoginModal({
        onSuccess: () => {
            invoke(async () => {
                // Auction detail is already refetch by the isLoggedIn watcher in page component, just wait for userMeta sto be et
                await until(userMeta).toBeTruthy();
                if (bailNeeded.value) isBailModalOpen.value = true;
            });
        },
    });

const onBailSuccess = (): void => {
    isBailModalOpen.value = false;
    bailSuccess.value = true;
};

const onBailFail = (): void => {
    isBailModalOpen.value = false;
    notifyError({ title: tt('notifications.genericError'), description: tt('auction.bail.error'), timeout: NotificationTimeout.Long });
};

const onBidButtonClick = (): void => {
    if (!isLoggedIn.value) {
        login();
    } else if (bailNeeded.value) {
        isBailModalOpen.value = true;
    } else if (userMeta.value?.canBid) {
        bidAmountToConfirm.value = bidAmount.value;
        isBidModalOpen.value = true;
    }
};

// WATCHERS
watch(isLoggedIn, () => {
    // Reset internal state on login change
    isBailModalOpen.value = false;
    bailSuccess.value = false;
    isBidModalOpen.value = false;
});

watch(bidOptions, () => (bidAmount.value = bidOptions.value[0].value));
</script>

<template>
    <div class="gap-2 lg:flex lg:flex-col lg:gap-6" :class="{ 'grid grid-cols-2': userMeta?.canBid }">
        <USelectMenu
            v-if="userMeta?.canBid"
            v-model="bidAmount"
            color="white"
            :options="bidOptions"
            value-attribute="value"
            :size="isLgOrGreater ? 'xl' : 'sm'"
        />
        <AdpAuctionPanelBidButton :loading="refetchAuctionDetailInProgress" @click="onBidButtonClick" />

        <LazyAdpBailModal v-if="bailNeeded" v-model="isBailModalOpen" :auction="auction" @success="onBailSuccess" @fail="onBailFail" />
        <LazyAdpBidModal
            v-if="userMeta?.canBid"
            :id="auction.id"
            v-model="isBidModalOpen"
            :bid-amount="bidAmountToConfirm"
            @success="refetchAuctionDetail"
        />
    </div>
</template>
