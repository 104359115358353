<script setup lang="ts">
import type { PropType } from 'vue';

import { AuctionState } from '~/apiClient';

type BidNoticeType = 'isAdmin' | 'sameCompany' | 'notScrapyard' | 'reserveNotReached';

// PROPS
const props = defineProps({
    type: { type: String as PropType<BidNoticeType>, required: true },
    auctionState: { type: String as PropType<AuctionState>, default: undefined },
});

// COMPOSABLES
const { tt } = useTypedI18n();

// COMPUTED
const icon = computed(
    () =>
        ({
            isAdmin: 'i-mdi-alert-circle',
            sameCompany: 'i-mdi-alert-circle',
            notScrapyard: 'i-mdi-alert-circle',
            reserveNotReached: 'i-mdi-shield-alert',
        })[props.type]
);

const iconColor = computed(() => (props.type === 'reserveNotReached' ? 'text-red-600' : 'text-primary'));
</script>

<template>
    <p class="flex items-center justify-start gap-2 text-xs md:justify-center lg:text-sm">
        <UIcon :name="icon" class="shrink-0 text-base" :class="iconColor" />
        <span v-if="type === 'isAdmin'" class="text-neutral-500">{{ tt('auction.auctionPanel.bidNotice.isAdmin') }}</span>
        <span v-else-if="type === 'sameCompany'" class="text-neutral-500">
            {{
                auctionState === AuctionState.Running
                    ? tt(`auction.auctionPanel.bidNotice.sameCompany.${AuctionState.Running}`)
                    : tt('auction.auctionPanel.bidNotice.sameCompany.base')
            }}
        </span>
        <NuxtLinkLocale v-else-if="type === 'notScrapyard'" to="faq#scrapping" class="underline" target="_blank">
            <span class="text-neutral-500">{{ tt('auction.auctionPanel.bidNotice.notScrapyard') }}</span>
        </NuxtLinkLocale>
        <NuxtLinkLocale v-else-if="type === 'reserveNotReached'" to="faq#reserve-price" class="underline" target="_blank">
            <i18n-t scope="global" tag="span" class="text-neutral-500" keypath="auction.auctionPanel.bidNotice.reservePriceNotReached.base">
                <span class="text-red-600">{{ tt('auction.auctionPanel.bidNotice.reservePriceNotReached.notReached') }}</span>
            </i18n-t>
        </NuxtLinkLocale>
    </p>
</template>
