<script setup lang="ts">
// PROPS
defineProps({
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
});
// COMPOSABLES
const { tt } = useTypedI18n();
const { isLgOrGreater } = useResponsive();
</script>

<template>
    <BaseIconBgButton
        v-if="isLgOrGreater"
        size="xl"
        color="fl-secondary"
        variant="solid"
        class="text-black"
        :label="tt('auction.bidButton')"
        icon-bg-color="fl-dark-blue"
        block
        :disabled="disabled"
        :loading="loading"
    />
    <UButton
        v-else
        size="sm"
        color="fl-secondary"
        variant="solid"
        class="text-black"
        :label="tt('auction.bidButton')"
        block
        :disabled="disabled"
        :loading="loading"
    />
</template>
