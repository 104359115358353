<script setup lang="ts">
import type { PropType } from 'vue';

import { type AuctionItemEntity } from '~/apiClient';

// PROPS
const props = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { tt, tn } = useTypedI18n();
const { isLgOrGreater } = useResponsive();
const { title, titleColor, titleAnnotation, titleAnnotationColor, isPriceHidden, isFeeNoticeHidden, priceColor, isCountdownVisible } =
    useAuctionStatusAdapter(toRef(() => props.auction));
</script>

<template>
    <!-- Desktop -->
    <div v-if="isLgOrGreater" class="flex flex-col gap-4">
        <p class="flex gap-3 text-sm font-extrabold uppercase tracking-widest lg:max-xl:flex-col">
            <span :class="titleColor">{{ title }}</span>
            <span v-if="titleAnnotation" :class="titleAnnotationColor">{{ titleAnnotation }}</span>
        </p>

        <p v-if="isPriceHidden" class="text-5xl font-semibold uppercase" :class="priceColor">{{ tt('auction.auctionPanel.priceNotAvailable') }}</p>
        <template v-else>
            <p class="text-5xl font-semibold" :class="priceColor">{{ tn(auction.currentPrice, 'currency') }}</p>
            <AdpAuctionPanelFeeNotice v-if="!isFeeNoticeHidden" />
        </template>

        <AdpCountdown v-if="isCountdownVisible" class="text-base" :auction="auction" />
    </div>

    <!-- Mobile/Tablet -->
    <div v-else class="flex flex-col gap-3">
        <p class="flex gap-3 text-xs font-extrabold uppercase tracking-widest">
            <span :class="titleColor">{{ title }}</span>
            <span v-if="titleAnnotation" :class="titleAnnotationColor">{{ titleAnnotation }}</span>
        </p>

        <div class="flex flex-col gap-0">
            <div class="flex items-center justify-between">
                <div>
                    <p v-if="isPriceHidden" class="text-2xl font-semibold uppercase" :class="priceColor">
                        {{ tt('auction.auctionPanel.priceNotAvailable') }}
                    </p>
                    <template v-else>
                        <p class="text-2xl font-semibold" :class="priceColor">{{ tn(auction.currentPrice, 'currency') }}<sup>*</sup></p>
                        <AdpAuctionPanelFeeNotice v-if="!isFeeNoticeHidden" class="hidden text-xs sm:block" />
                    </template>
                </div>

                <AdpCountdown v-if="isCountdownVisible" class="flex flex-col items-center text-sm" :auction="auction" />
            </div>
            <AdpAuctionPanelFeeNotice v-if="!isFeeNoticeHidden" class="text-xs sm:hidden" />
        </div>
    </div>
</template>
