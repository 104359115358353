<script setup lang="ts">
import type { PropType } from 'vue';

import type { AuctionAssetBusEntity } from '~/apiClient';

// COMPOSABLES
const { tt } = useTypedI18n();
const { locale } = useI18n();
const { swiperElement, activeIndex } = useSwiperNavigation();

// PROPS
defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity>, required: true },
});

// DATA
const isLightboxOpen = ref<boolean>(false);
const lightboxInitialSlide = ref<number>(0);

// HANDLERS
const openLightbox = (index: number): void => {
    lightboxInitialSlide.value = index;
    isLightboxOpen.value = true;
};

const onLightboxIndexChange = (index: number): void => {
    swiperElement.value?.swiper.slideTo(index);
};
</script>

<template>
    <section class="flex flex-col gap-6">
        <h2 class="text-lg font-semibold md:text-3xl">{{ tt('adp.detailsTitle') }}</h2>

        <p v-if="!!asset.damageNotes[locale]">
            {{ asset.damageNotes[locale] }}
        </p>

        <AdpSwiperNavigation class="px-4" :swiper-element="swiperElement" :active-index="activeIndex" rewind>
            <ClientOnly>
                <swiper-container
                    ref="swiperElement"
                    class="max-h-full w-full shrink-0"
                    :slides-per-view="Math.min(2, asset.images.damage.length)"
                    :lazy-preload-prev-next="1"
                    :space-between="20"
                    :breakpoints="{
                        768: {
                            spaceBetween: 28,
                            slidesPerView: Math.min(4, asset.images.damage.length),
                        },
                    }"
                    rewind="true"
                    virtual="true"
                >
                    <swiper-slide v-for="(image, index) in asset.images.damage" :key="image" class="m-auto" lazy="true">
                        <NuxtImg
                            class="aspect-[4/3]cursor-zoom-in cursor-pointer rounded-xl border object-cover"
                            :src="image"
                            sizes="480 md:240"
                            densities="x1"
                            loading="lazy"
                            :draggable="false"
                            @click="openLightbox(index)"
                        />
                    </swiper-slide>
                </swiper-container>

                <template #fallback>
                    <div class="grid grid-cols-2 gap-5 md:hidden">
                        <NuxtImg
                            v-for="image in asset.images.damage.slice(0, 2)"
                            :key="image"
                            class="aspect-[4/3] cursor-zoom-in rounded-xl border object-cover"
                            :src="image"
                            :width="480"
                            densities="x1"
                            :draggable="false"
                        />
                    </div>
                    <div class="hidden grid-cols-4 gap-7 md:grid">
                        <NuxtImg
                            v-for="image in asset.images.damage.slice(0, 4)"
                            :key="image"
                            class="aspect-[4/3] cursor-zoom-in rounded-xl border object-cover"
                            :src="image"
                            :width="240"
                            densities="x1"
                            :draggable="false"
                        />
                    </div>
                </template>
            </ClientOnly>
        </AdpSwiperNavigation>

        <div class="flex justify-center">
            <UButton
                class="md:justify-unset w-full justify-start md:w-auto"
                size="xl"
                color="white"
                :label="tt('adp.imageGallery', { totalImages: asset.images.damage.length })"
                icon="i-heroicons-squares-2x2-solid"
                leading
                @click="openLightbox(0)"
            />
        </div>

        <LazyAdpLightboxImage
            v-model="isLightboxOpen"
            :items="asset.images.damage"
            :initial-slide="lightboxInitialSlide"
            @active-index-change="onLightboxIndexChange"
        />
    </section>
</template>
