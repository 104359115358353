<script setup lang="ts">
import { AssetType, type AuctionAssetBusEntity, BackendErrorCode, getApiAuctionDetailQueryKey, useApiAuctionDetail } from '~/apiClient';
import { AUCTION_DETAIL_QUERY_KEY_PREFIX } from '~/constants/queryKeyPrefix';
import type { Breadcrumb } from '~/types/breadcrumb.type';

definePageMeta({
    key: 'auction-detail-static', // Prevent full page re-rendering on locale change
});

// COMPOSABLES
const { id, slug } = useAuctionDetailParams();
const { isLoggedIn } = useAuthUtils();
const localePath = useLocalePath();
const { tt } = useTypedI18n();
const { formatAlpRoute } = useAlpRouteUtils();

// DATA
const {
    data: auction,
    error,
    isError,
    suspense,
    refetch,
} = useApiAuctionDetail(id, {
    query: {
        queryKey: [AUCTION_DETAIL_QUERY_KEY_PREFIX, ...getApiAuctionDetailQueryKey(id)],
    },
});

// COMPUTED
const asset = computed<AuctionAssetBusEntity | undefined>(() => auction.value?.assets?.[0]);
const assetType = computed<AssetType>(() => asset.value?.assetType || AssetType.Bus);
const breadCrumbs = computed<Breadcrumb[] | undefined>(
    () =>
        asset.value && [
            {
                label: tt('homepage.link'),
                to: 'index',
            },
            {
                label: tt(`auctions.page.asset.${AssetType.Bus}`),
                to: formatAlpRoute({ assetType: AssetType.Bus }),
            },
            {
                label: tt(`asset.class.${asset.value!.class[0]}`),
                to: formatAlpRoute({ assetType: AssetType.Bus, assetClass: asset.value!.class[0] }),
            },
            {
                label: asset.value.brand.brandValue,
                to: formatAlpRoute({
                    assetType: AssetType.Bus,
                    assetClass: asset.value!.class[0],
                    brandUrlKey: asset.value!.brand.urlKey,
                }),
            },
            {
                label: asset.value!.model.modelValue,
                to: formatAlpRoute({
                    assetType: AssetType.Bus,
                    assetClass: asset.value.class[0],
                    brandUrlKey: asset.value.brand.urlKey,
                    modelUrlKey: asset.value.model.urlKey,
                }),
            },
        ]
);
// WATCHERS
watch(isLoggedIn, (value, oldValue) => {
    value !== oldValue && refetch();
});

// Perform await only if the id is correct resolved to the path
!!id.value && (await suspense());

if (slug.value === auction.value?.id) {
    // Redirect to correct slug
    navigateTo(localePath({ name: 'auction-slug', params: { slug: auction.value.slug } }));
} else if (
    !id.value ||
    error.value?.data?.error.code === BackendErrorCode.EntityNotFound || // backend 404
    error.value?.data?.error.code === BackendErrorCode.InvalidUUID || // backend 400
    slug.value !== auction.value?.slug // Slug is not correct
) {
    showError({ statusCode: 404 });
} else if (isError.value) {
    // Backend 500
    showError({ statusCode: 500 });
}

// SEO
useSeoMeta({
    title: () =>
        tt('seo.auction.title', {
            asset: tt(`asset.type.${assetType.value}`, 1), // Single form in ADP
            brand: asset.value?.brand.brandValue,
            model: asset.value?.model.modelValue,
            version: asset.value?.version.versionValue,
            vin: asset.value?.vin,
        }),
    ogTitle: tt('seo.auction.title', {
        asset: tt(`asset.type.${assetType.value}`, 1),
        brand: asset.value?.brand.brandValue,
        model: asset.value?.model.modelValue,
        version: asset.value?.version.versionValue,
        vin: asset.value?.vin,
    }),
    description: tt('seo.auction.description', {
        asset: tt(`asset.type.${assetType.value}`, 1),
        brand: asset.value?.brand.brandValue,
        model: asset.value?.model.modelValue,
        version: asset.value?.version.versionValue,
        vin: asset.value?.vin,
    }),
    ogDescription: tt('seo.auction.description', {
        asset: tt(`asset.type.${assetType.value}`, 1),
        brand: asset.value?.brand.brandValue,
        model: asset.value?.model.modelValue,
        version: asset.value?.version.versionValue,
        vin: asset.value?.vin,
    }),
});
</script>
<template>
    <article v-if="auction && asset">
        <BaseWidthContainer class="relative gap-7 py-6 lg:grid lg:grid-cols-3">
            <div class="flex flex-col gap-y-12 lg:col-span-2">
                <BaseBreadcrumbs v-if="breadCrumbs" class="text-neutral-500" :items="breadCrumbs" />
                <AdpTitleSection :asset="asset" />
                <AdpGallery :asset="asset" />
                <AdpSpecs :asset="asset" />
                <AdpDetails :asset="asset" />
            </div>
            <div class="sticky bottom-2 z-10 mt-6 lg:static lg:col-span-1 lg:mt-0">
                <div class="lg:sticky lg:top-[92px]">
                    <AdpAuctionPanel :auction="auction" />
                    <AdpAuctionPanelContactUs class="mt-6 hidden lg:block" />
                </div>
            </div>
        </BaseWidthContainer>
        <MktgWhyFleequid class="border-b border-white/15" />
    </article>
</template>
