<script setup lang="ts">
import { type AuctionAssetBusEntity } from '~/apiClient';

// PROPS
const props = defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity>, required: true },
});

// COMPOSABLES
const { tt, td, formatUnits } = useTypedI18n();

// COMPUTED
const firstRegistration = computed(() => (props.asset?.firstRegistration ? Date.parse(props.asset.firstRegistration) : undefined));
</script>

<template>
    <div class="flex flex-wrap gap-3">
        <AuctionRestrictionBadge :asset="asset" />
        <UBadge v-if="firstRegistration" color="fl-dark-blue" variant="solid" size="lg" class="flex gap-x-2">
            <UIcon class="h-5 w-5" name="i-mdi-bus" />
            {{ tt('asset.firstRegistration') }}: {{ td(firstRegistration, 'mmyyyy') }}
        </UBadge>
        <UBadge color="fl-dark-blue" variant="solid" size="lg" class="flex gap-x-2">
            <UIcon class="h-5 w-5" name="i-mdi-gauge" />
            {{ formatUnits.km(asset.km) }}
        </UBadge>
        <UBadge color="fl-dark-blue" variant="solid" size="lg" class="flex gap-x-2">
            <UIcon class="h-5 w-5" name="i-mdi-engine" />
            {{ tt(`asset.euroNorm.${asset.euroNorm}`) }}
        </UBadge>
    </div>
</template>
