import { Swiper } from 'swiper';

type UseSwiperNavigationReturn = {
    swiperElement: Ref<(HTMLElement & { swiper: Swiper }) | null>;
    activeIndex: Ref<number>;
};

export const useSwiperNavigation = (): UseSwiperNavigationReturn => {
    // DATA
    const swiperElement = ref<(HTMLElement & { swiper: Swiper }) | null>(null);
    const activeIndex = ref<number>(0);

    // WATCHERS
    watch(
        () => swiperElement.value?.swiper,
        () => {
            if (!swiperElement.value) return;
            activeIndex.value = swiperElement.value.swiper.activeIndex;
            swiperElement.value.swiper.on('activeIndexChange', function (swiper: Swiper): void {
                activeIndex.value = swiper.activeIndex;
            });
        }
    );

    return {
        swiperElement,
        activeIndex,
    };
};
