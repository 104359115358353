<script setup lang="ts">
import type { PropType } from 'vue';

import { type AuctionItemEntity, AuctionState, BackendErrorCode, type UserMeta, UserRole } from '~/apiClient';

// PROPS
const props = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { isTablet } = useResponsive();
const { data: authData } = useAuthUtils();
const { showReserveNotice, statusSectionColor } = useAuctionStatusAdapter(toRef(() => props.auction));

// COMPUTED
const userMeta = computed<UserMeta | undefined>(() => props.auction.userMeta);

const notScrapyard = computed(() => userMeta.value?.cantBidReason === BackendErrorCode.CantBidScrapAuctionNotScrapyardUser);
const sameCompany = computed(() => userMeta.value?.cantBidReason === BackendErrorCode.CantBidUserCompanyEqSellerCompany);
const isAdmin = computed(() => authData.value?.role === UserRole.Admin);

const hasNotice = computed(() => notScrapyard.value || sameCompany.value || isAdmin.value || showReserveNotice.value);
const showPlaceBid = computed(() => props.auction.state === AuctionState.Running && props.auction.userMeta?.reservePriceReached !== true);

const showBidSection = computed(() => hasNotice.value || showPlaceBid.value);

const statusSectionClasses = computed(() => [
    ...insertIf(showBidSection.value, isTablet.value ? 'rounded-l-2xl' : 'rounded-t-2xl'),
    ...insertIf(!showBidSection.value, 'col-span-2', 'rounded-2xl'),
    statusSectionColor.value,
]);
</script>

<template>
    <div class="rounded-2xl drop-shadow-xl md:grid md:grid-cols-2 lg:block lg:drop-shadow-none">
        <div class="border border-solid border-gray-200 p-4 lg:p-6" :class="statusSectionClasses">
            <AdpAuctionPanelStatusSection :auction="auction" />
        </div>
        <div
            v-if="showBidSection"
            class="flex flex-col justify-center border border-solid border-gray-200 bg-white p-4 lg:p-6"
            :class="isTablet ? 'rounded-r-2xl border-l-0' : 'rounded-b-2xl border-t-0'"
        >
            <div class="flex flex-col gap-3">
                <AdpAuctionPanelBidNotice v-if="notScrapyard" type="notScrapyard" />
                <AdpAuctionPanelBidNotice v-else-if="sameCompany" type="sameCompany" :auction-state="auction.state" />
                <AdpAuctionPanelBidNotice v-else-if="isAdmin" type="isAdmin" />
                <template v-else>
                    <AdpAuctionPanelBidNotice v-if="showReserveNotice" type="reserveNotReached" />
                    <AdpAuctionPanelPlaceBid v-if="showPlaceBid" :auction="auction" />
                </template>
            </div>
        </div>
    </div>
</template>
