<script setup lang="ts">
const { tt } = useTypedI18n();
</script>

<template>
    <div class="text-center">
        <p class="text-gray-500">{{ tt('auction.needSupport') }}</p>
        <p>
            <NuxtLinkLocale to="mailto:info@fleequid.com" class="text-black underline" target="_blank">
                {{ tt('auction.contactUs') }}
            </NuxtLinkLocale>
        </p>
    </div>
</template>
